"use client";

import useGraphQlQuery from "@/utility/useGraphQlQuery";
import { useEffect, useRef, useState } from "react";
import Cookies from "universal-cookie";
import { gtagEvent } from "@/utility/googleAnalyticsEvents";

const LIQUID_POPUP = "liquid_popup";
const TEN_PERCENT_OFF = "ten-percent-off";

const TenPercentOffPopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const cookies = new Cookies();
  const data = cookies.get(LIQUID_POPUP);
  const [email, setEmail] = useState("");

  const formRef = useRef(null);

  const { submitForm } = useGraphQlQuery();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = formRef.current;

    const data = {
      email: email,
    };

    await submitForm({
      formName: "Ten Percent Off",
      formData: data,
      reset: form.reset(),
    });

    setCookie();
    setShowPopup(false);
  };

  const thirtyDaysFromNow = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 30);
    return currentDate;
  };

  const setCookie = () => {
    const cookies = new Cookies();
    cookies.set(
      LIQUID_POPUP,
      {
        [TEN_PERCENT_OFF]: true,
      },
      {
        sameSite: "strict",
        expires: thirtyDaysFromNow(),
      },
    );
  };

  const closePopup = (src) => {
    setShowPopup(false);
    gtagEvent({ action: "close_popup", label: "src", value: src });
  };

  useEffect(() => {
    if (!data || !data[TEN_PERCENT_OFF]) {
      const timeout = setTimeout(() => {
        setShowPopup(true);
        gtagEvent({ action: "open_popup" });
      }, 10000); // 10 seconds delay in milli seconds
      return () => clearTimeout(timeout);
    }
  }, [data]);

  return (
    <>
      {showPopup && (
        <>
          <div
            className="w-100 h-100 fixed bottom-0 left-0 right-0 top-0 z-[1000] flex items-center justify-center p-4"
            style={{ backgroundColor: "rgb(0, 0, 0, 0.3)" }}
          >
            <div className="max-w-[550px] rounded-lg border border-4 bg-primary px-4 py-2 md:p-8">
              <div className="flex justify-end">
                <img
                  src="/images/icons/x.svg"
                  alt="logo"
                  className="h-6 cursor-pointer hover:opacity-70"
                  onClick={() => closePopup("x")}
                />
              </div>

              <img
                src="/images/logo/pink-logo.svg"
                alt="logo"
                className="mx-auto w-[55%] items-center rounded-lg "
              />
              <p className="py-3 text-center text-lg text-white md:text-xl">
                Subscribe for LiquiDonate's product updates and newsletter!
              </p>

              <form
                ref={formRef}
                onSubmit={handleSubmit}
                className="mx-auto flex w-full max-w-[85%] flex-col justify-center gap-x-4 gap-y-4 py-4"
              >
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter you email"
                  className="outline-non input input-bordered w-full border-black bg-white focus:outline-none"
                />
                <button
                  type="submit"
                  className="ease-in-up font-lg rounded-lg bg-secondary px-8 py-3 font-extrabold uppercase text-primary transition duration-300  hover:bg-secondary/80 hover:shadow-signUp"
                  onClick={handleSubmit}
                  disabled={!email}
                >
                  Keep me updated
                </button>
                <p className="text-center text-sm text-white">
                  By signing up, you agree to receive email marketing and can
                  unsubscribe at any time.
                </p>
              </form>

              <div className="py-4 text-center">
                <span
                  onClick={() => closePopup("no-discount-thanks")}
                  className="cursor-pointer text-lg uppercase text-white underline"
                >
                  No, thanks.
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TenPercentOffPopup;
